import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";
import moneyFormat from "../../utilities/moneyFormat";

const Withdraw = () => {

    const [pageTile, setPageTitle] = useState("Transactions")
    
    const [isLoading, setLoading] = useState(false)
    const [tx, setTx] = useState([])
    const [walletType, setWalletType] = useState(null)
    const [amount, setAmount] = useState(null)
    const [country, setCountry] = useState(null)

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        userProfile()
        setLoading(false)
      }

      const userProfile = async () => {
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            setCountry(y.country)
        }
      }

      const getTransactions = async () => {
        setLoading(true)
        let x = await requestHandler.get('user/transaction/all', true);
        if(x != null && x.status === 1){
            setTx(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const withdrawAction = async () =>{
        let url = 'user/transaction/withdraw';
        setLoading(true)
        let x = await requestHandler.post(url, {wallet_type: walletType, amount: amount}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
                setAmount('')
                getTransactions()
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

      
    useEffect(() => {
        checkIfLoggedIn()
        getTransactions()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
             <h2 class="small-title">Transactions</h2>
           
            <form className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                      <div className="mb-3">
                        <select onChange={e => setWalletType(e.target.value)} className="form-control">
                        <option value=''>Select wallet </option>
                        {/*<option value={'main'}>Main</option>*/}
                        <option value={'referral'}>Affiliate</option>
                        <option value={'agent'}>Agent</option>
                            </select>
                      </div>
                      </div>
                      <div className="col-6">
                      <div className="mb-3">
                        <input value={amount} onChange={e => setAmount(e.target.value)} className="form-control" type="tel" placeholder="Enter Amount" />
                      </div>
                      </div>
                      <div className="col-12">
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={withdrawAction} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Withdraw</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                      </div>
                    </div>
                    
                  </form>

            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

                <table className="table table-striped">
        <thead>
          <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Type</th>
          <th>Status</th>
          </tr>
          </thead>
          <tbody>
      {tx.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}</td>
          <td>{number_format(x.amount)}</td>
          <td>{x.type}</td>
          <td>{x.status === 0? 'Pending' : x.status === 1? 'Successful' : 'Rejected'}</td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default Withdraw;
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requestHandler from "../utilities/requestHandler";

const LeftMenu = () =>{

  const [loggedIn, setLoggedIn] = useState(false)
  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [account_type, setAccountType] = useState(localStorage.getItem('account_type'));

  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x != null && x.status === 1){
        let y = x.message;
        setLoggedIn(true)
    }
}

useEffect(()=>{
checkIfLoggedIn()
},[]);

    return (

      <div class="col-auto d-none d-lg-flex">
          {account_type !== null? <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
            <li>
              <Link to="/">
                <i data-cs-icon="home" class="icon" data-cs-size="18"></i>
                <span class="label">Dashboard</span>
              </Link>
            </li>
            {/*<li>
              <Link to="/recent">
                <i data-cs-icon="news" class="icon" data-cs-size="18"></i>
                <span class="label">Recent</span>
              </Link>
            </li>
            <li>
              <Link to="/user/contents/shared">
                <i data-cs-icon="share" class="icon" data-cs-size="18"></i>
                <span class="label">Shared</span>
              </Link>
            </li>*/}
            <li>
              <Link to="/user/referrals">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Referrals</span>
              </Link>
            </li>


            <li>
              <a href="#" data-bs-target="#vendor">
                <i data-cs-icon="server" class="icon" data-cs-size="18"></i>
                <span class="label">CAC Registrations</span>
              </a>
              <ul>
              <li>
                  <Link to="/cac/regsiterations">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">All Registrations</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cac/regsiter/name">
                    <i data-cs-icon="chart-3" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Business Name</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cac/regsiter/limited">
                    <i data-cs-icon="chart-2" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Limited Liability</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cac/regsiter/ngo">
                    <i data-cs-icon="building" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">NGO Registration</span>
                  </Link>
                </li>
                <li>
                  <a href="/cac/upgrade">
                    <i data-cs-icon="upload" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Upgrade Business name</span>
                  </a>
                </li>
                {/*<li>
                  <a href="/auth/coupon">
                    <i data-cs-icon="cart" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Buy Coupon</span>
                  </a>
                </li>*/}
              </ul>
            </li>


            <li>
              <a href="#" data-bs-target="#profile">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Account</span>
              </a>
              <ul>
              <li>
                  <Link to="/user/profile">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user/withdraw">
                    <i data-cs-icon="dollar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Withdraw</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user/banks">
                    <i data-cs-icon="suitcase" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Bank/Mobile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user/transactions">
                    <i data-cs-icon="list" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Transactions</span>
                  </Link>
                </li>
               {/* <li>
                  <Link to="/user/profile/overview">
                    <i data-cs-icon="file-chart" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Overview</span>
                  </Link>
              </li> */}
                <li>
                  <Link to="/user/profile/password/update">
                    <i data-cs-icon="shield" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Update Password</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          :
          ''
}
        </div>
    )
}
export default LeftMenu;
import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"

const HowItWorks = () => {

    const [pageTile, setPageTitle] = useState("How It Works")
    const [about, setAbout] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const websiteSettings = async () => {
        setLoading(true)
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            let y = x.message;
            setAbout(x.message.about)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        document.title = pageTile
       // websiteSettings()
    },[pageTile])

    return(
        <div className="col">
          <h2 class="small-title">How It Works</h2>
          {isLoading? <Loading/> : ''}
<div class="row g-2">

              <div class="card">
              <div class="card-body">
              <div style={{whiteSpace: "pre-wrap"}}>
              Introducing TheMainceos – where hustle meets heart, and dreams turn into reality! Since 2020, we've been on a mission to fuel your journey toward financial freedom and personal growth in the digital era. 
With our killer affiliate marketing system, we don't just talk the talk; we walk the walk – and we've got the Google record to prove it! 💪
<br/><br/>
Check out some of our awesome features:
<br/><br/>
🎓<b>Monthly Training</b> Level up your mindset and entrepreneurial game with our monthly training sessions. Learn, earn, and conquer!
<br/><br/>
💰<b>Loan and Grants</b> Need a financial boost? We've got your back with opportunities for loans and grants to kickstart your business ventures, also explain process on getting a loan and grant from third party. 
<br/><br/>
📢<b>News and Promo Kits</b> Spread the word and reel in clients with our promotional content on TheMainceos Blog. It's marketing made easy!
<br/><br/>
🎡<b>TheMainceos Lucky Wheel</b> Feeling lucky? Spin the wheel, win prizes, and stack up those earnings!
<br/><br/>
🛒<b>TheMainceos E-commerce Feature</b> Buy, sell, and shine on our marketplace – with free access and verification, your products will reach thousands!
<br/><br/>
📲<b>VTU Enablement</b> Top up your data, airtime, and more using your hard-earned affiliate earnings. Talk about convenience!
<br/><br/>
💸<b>Transferring Funds</b> Move your earnings between dashboards faster than you can say "cash in hand." from the affiliate dashboard to the sales agent platform 
<br/><br/>
🏆<b>Ranks and Incentives</b> Hit your targets and watch the rewards roll in – from ₦500,000 to ₦1 million, we've got bonuses for days!
<br/><br/>
🌍<b>Global Enablement</b> From Lagos to London, we've got you covered with stress-free withdrawals and payments.
<br/><br/>
Ready to rake in those earnings? Here's how:
<br/><br/>
- 💼<b>Affiliate Income</b> Score 50% per referral and watch those earnings stack up!
<br/><br/>
- 🤝<b>Sales Commission</b> Cash in on successful service purchases, credited straight to your sales agent account.
<br/><br/>
- 💰<b>Derivatives Sales Commissions</b> Earn from referrals and their referrals – it's like money on autopilot!
<br/><br/>
- 📆<b>Daily Login Bonus</b> Get rewarded just for showing up. It's that easy!
<br/><br/>
- 📣<b>Engagement Earnings</b> Turn your daily advert posts into cold, hard cash!
<br/><br/>
PS; SHARE VIRAL POSTS IS A MUST ✌️
<br/><br/><br/><br/>


And when it's time to cash out:
<br/><br/>
- 💳<b>Affiliate Commissions</b> Minimum withdrawal of ₦3000 weekly, straight into your bank account.
<br/><br/>
- 💵<b>Sales Commission</b> Paid directly into your bank account between the 27th to 30th of every month.
<br/>
But wait, there's more! Maximize those points:
<br/>
1. 💡 Accumulate for grants or loans.<br/>
2. 📱 Convert for VTU products.<br/>
3. 💵 Convert for cash (when announced).<br/>
4. 🎟️ Convert for coupon codes.<br/>
5. 📚 Use for courses or eBooks from our marketplace.
<br/><br/>
Unleash the magic of viral sharing with TheMainceos! 🚀 Here's why it's a total game-changer:
<br/><br/>
1. <b>Engagement Booster:</b> Dive into discussions about student behavior and showcase TheMainceos like a pro across all social media platforms. It's where the action happens!
<br/><br/>
2. <b>Brand Rockstar:</b> Transform into a walking advertisement for TheMainceos and witness your influence skyrocket. You're not just a member; you're a vital part of a movement!
<br/><br/>
3. <b>Content Wizard:</b> Master the art of captivating your audience and dominate social feeds with irresistible content. It's the secret ingredient to standing out from the crowd!
<br/><br/>
4. <b>Point Payoff:</b> Share, share, share, and watch those points soar! Because when it's time to cash in on rewards, viral sharing is your ultimate ticket to success.
<br/><br/>
So, let's spread the word, earn big, and create waves together with TheMainceos! 🌟
<br/><br/>
Join us as an affiliate, sales agent, or both – because at TheMainceos, we're all about learning while earning! And hey, if you're feeling extra ambitious, why not become a partner or investor and ride the wave with us? The possibilities are endless!
<br/><br/>
<b>Who's Eligible for TheMainceos?</b><br/><br/>

Anyone ready to boost their bank account, explore new income streams, or master the art of making money online. Registration fees start at ₦3,000, so don't hesitate – hit up our vendors on WhatsApp and let's get this party started! 🎉
<br/><br/>


SEE YOU AT THE TOP 🔺
                </div>
                </div>

                </div>

</div>

        </div>  
    )
}

export default HowItWorks;
import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"

const Login = () => {

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Log In")
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [emailAvailable, setEmailAvailable] = useState(false)

    const [loggedIn, setLoggedIn] = useState(false)
    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 1){
        window.location.href = "/"
      }
  }
    
    const clearFileds = () => {
setEmail('')
setPassword('')
    }

    useEffect(() => {
        document.title = pageTile
        checkIfLoggedIn()
    },[pageTile])

    const checkEmail = async () => {
        setLoading(true)
        //setData({email: email})
        if(email != null){
            
        let x = await requestHandler.post('auth/check/email', {email: email}, false, false)
        if(x != null && x.status === 1){
            setEmailAvailable(true)
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else if(x != null && x.status === 2){
            let askToCreate = window.confirm("No account is associated with this email address. Click OK to create a new account.")
            if(askToCreate) window.location.href = "/auth/register?email="+email;
            else toast.error('Account not found')
        }
        else{
            toast.error('Something went wrong')
        }
        }
        setLoading(false)
    }

    const loginAction = async () => {
        setLoading(true)
        if(email != null && password != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('auth/login', {email: email, password: password}, false, false)
            if(x != null && x.status === 1){
                //let y = x.message;
                toast.success(x.message)
                //set login token
                localStorage.setItem('access_token', x.access_token)
                localStorage.setItem('account_type', x.account_type)
                //go back after log in
                window.location.href = "/"
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        }
        else 
        {
            clearFileds()
        }
        setLoading(false)
    }

    const onLoginEnter = (event) =>{
      if(event.keyCode === 13){
          loginAction()
      }
    }

    return(
     
      <div class="row justify-content-center align-items-center">
<div class="col-md-6 col-lg-6 col-xl-6">        
<form className="card tooltip-end-top" novalidate>
                              <div className="card-body">
                              <h1>Welcome!</h1>
                                <p className="text-alternate mb-4">Please use your credentials to login.</p>
                                <div className="input-group mb-3 filled">
                                <div className="input-group-text"> 
                                <i className="fa-regular fa-envelope"></i>
                                  </div>
                                  <input value={email} onChange={e => setEmail(e.target.value)} onKeyDown={e => onLoginEnter(e)} className="form-control" placeholder="Email"/>
                                </div>
                                {/*<div style={{display: emailAvailable? `flex` : `none`}} className="mb-3 filled">
                                  <i data-cs-icon="lock-off"></i>
                                  <input value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => onLoginEnter(e)} className="form-control" type="password" placeholder="Password" />
                                </div>*/}
                                <div className="input-group mb-3 filled">
                                <div className="input-group-text"> 
                                  <i className="fa-regular fa-unlock"></i>
                                  </div>
                                  <input value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => onLoginEnter(e)} className="form-control" type={showPassword? 'text' : 'password'} placeholder="Password" />
                                 <div onClick={()=>{showPassword? setShowPassword(false) : setShowPassword(true)}} className="input-group-text"> 
                                 <i className={showPassword? "fa-regular fa-eye text-center" : "fa-regular fa-eye-slash text-center"}></i>
                                 </div>
                                </div>
                              </div>
                              <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                                <div className="row">
                                <Link to="/auth/password/reset" className="me-3">Forgot Password?</Link>
                                {/*<Link to="/auth/register" className="me-3">Create Account</Link>*/}
                                </div>
                                <div>
                                  {/*isLoading? <Loading/> : 
                                  <button onClick={emailAvailable? loginAction : checkEmail} className="btn btn-icon btn-icon-end btn-primary" type="button">
                                    <span>{emailAvailable? 'Log In' : 'Next'}</span>
                                    <i data-cs-icon="chevron-right"></i>
                                  </button>*/}
                                  {isLoading? <Loading/> : 
                                  <button onClick={loginAction} className="btn btn-icon btn-icon-end btn-primary" type="button">
                                    <span>Log In</span>
                                    <i data-cs-icon="chevron-right"></i>
                                  </button>}
                                </div>                      
                              </div>
          
                              
                            </form>  
                           <div class="row d-flex justify-content-center align-items-center">
                      <div className="row m-5">
                      <Link to="/auth/register" className="btn btn-block btn-success">
                          <span>Create Account</span>
                        </Link>
                        </div>
                      </div> 
                </div>

                <div class="col-md-6 col-lg-6 col-xl-6 d-none d-lg-block">  
                <img src={Images().logo}
          class="img-fluid" alt="Sample image" />
                </div>
                </div>
    )
}

export default Login;
import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"
import countriesAndDialCodes from "../../utilities/countries"
import moneyFormat from "../../utilities/moneyFormat"
import VerifyCouponWidget from "./verifyCouponWidget"

const PurchaseCoupon = () => {

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Purchase Coupon")
    //const [country, setCountry] = useState(localStorage.getItem('country'));
    const [country, setCountry] = useState('Nigeria');
    const [officialEmail, setOfficialEmail] = useState(null)
    const disclaimer = "Kindly report any vendor that you are not satisfied with. If you want to become a vendor you may contact us.";
    const [isLoading, setLoading] = useState(false)
    const [vendors, setVendors] = useState([])

    const websiteSettings = async () => {
      let x = await requestHandler.get('settings/get', false);
      if(x != null && x.status === 1){
          let y = x.message;
          setOfficialEmail(y.email)
      }
  }

    useEffect(() => {
      getVendors()
      websiteSettings()
        document.title = pageTile
    },[pageTile])



    const getVendors = async () => {
        setLoading(true)

        if(country != null){            
        let x = await requestHandler.post('vendor/search_by_country', {country: country}, false, false)
        if(x != null && x.status === 1){
            setVendors(x.message)
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else{
            toast.error('Something went wrong')
        }
        }
        setLoading(false)
    }

    return(
     
      <div class="row justify-content-center align-items-center">
<div class="col-md-6 col-lg-6 col-xl-6">        
                  <form className=" tooltip-end-top mb-5" novalidate>
                    <div className="card-body">
                    <h1>Vendors</h1>
                    <p className="text-alternate mb-4">{disclaimer}</p>
{/*                    <p className="text-alternate mb-4"><b>Email: {officialEmail}</b></p>
                    <p className="text-alternate mb-4"><b>Coupon Price: {moneyFormat(5, country)}</b></p>
*/}                    
                    {isLoading? <Loading/> : ''}
                    
                      
                    </div>
                     <div style={{display: vendors.length > 0? 'flex' : 'none'}} className="row">
                        <div className="row g-2">

                        {
                        vendors.map(x => <div class="col-12 col-lg-6">
                      <div class="card">
                      <a href={'https://wa.me/'+x.phone} target="_blank" rel="noreferrer" class="row g-0 sh-11">
                          <div class="col-auto h-100">
                            <img src={x.picture !== null && x.picture !== ""? x.picture : Images().logo} alt="user" class="card-img card-img-horizontal sw-11 sw-md-14 theme-filter" />
                            <div class="position-absolute bg-foreground opacity-75 text-primary px-1 py-1 text-extra-small b-2 s-2 rounded-lg">WhatsApp</div>
                          </div>
                          <div class="col">
                            <div class="card-body d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                              <div class="d-flex flex-column">
                                <div class="font-heading">{x.first_name} {x.last_name}</div>
                                <div class="text-uppercase text-muted text-truncate">{x.bank_name}</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    )}
                    
                        </div>

                      </div>
                      

                    
                  </form>  
                      </div>

                <div class="col-md-6 col-lg-6 col-xl-6">  
                <VerifyCouponWidget/>
                </div>
                </div>
    )
}

export default PurchaseCoupon;
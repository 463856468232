import React, {Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import requestHandler from '../utilities/requestHandler';
import PopularContent from './landing/popularContent';
import truncate from '../utilities/truncateString';
import TimeInWords from '../utilities/timeInWords';
import AdsBanner1 from './ads/banner1';

const Footer = () => {
    
    const [title, setTitle] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])
    const [search, setSearch] = useState(null)

      const searchContents = async () => {
        if(search !== null && search.length > 3)
    {        
    setLoading(true)
        let x = await requestHandler.get('content/'+search+'/search', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        setLoading(false)
      }
      }

    const websiteSettings = async () => {
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            let y = x.message;
            setTitle(y.title)
        }
    }

    useEffect(()=>{
        websiteSettings()
       // if(tawkToUrl != null) tawkToChat.TawkToChat(tawkToUrl)
      },[])

      return ( <div className='row'>
          <div class="footer-content">
            <div class="container">
            {/*<AdsBanner1 />*/}
              <div class="row">
                <div class="col-12 col-sm-6">
                  <p class="mb-0 text-muted text-medium">{title}</p>
                </div>
                <div class="col-sm-6 d-none d-sm-block">
                  <ul class="breadcrumb pt-0 pe-0 mb-0 float-end">
                    <li class="breadcrumb-item mb-0 text-medium">
                        <Link to="#" class="btn-link">About</Link>
                        </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade modal-under-nav modal-search modal-close-out" id="searchPagesModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body ps-5 pe-5 pb-0 border-0">
            <input value={search} onChange={e => {setSearch(e.target.value); searchContents()}} id="searchPagesInput" class="form-control form-control-xl borderless ps-0 pe-0 mb-1 auto-complete" type="text" autocomplete="off" />
          </div>
          <div class="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
            <span class="text-alternate d-inline-block m-0 me-3">
              <i data-cs-icon="arrow-bottom" data-cs-size="15" class="text-alternate align-middle me-1"></i>
              <span class="align-middle text-medium">Navigate</span>
            </span>
            <span class="text-alternate d-inline-block m-0 me-3">
              <i data-cs-icon="arrow-bottom-left" data-cs-size="15" class="text-alternate align-middle me-1"></i>
              <span class="align-middle text-medium">Select</span>
            </span>

            {posts.map(x => <div class="col-12 col-lg-12">
              <div class="card">
              <Link to={"/content/"+x.unique_id+"/view"} class="row g-0 sh-11">
                  <div class="col-auto h-100">
                  {x.post_image != null? <img src={x.post_image} class="card-img card-img-horizontal sw-11 sw-md-14 theme-filter" alt="card image" />
                :
                <video src={x.post_video} className="card-img card-img-horizontal sw-11 sw-md-14 theme-filter" alt="video"></video>    
                }
                    <div class="position-absolute bg-foreground opacity-75 text-primary px-1 py-1 text-extra-small b-2 s-2 rounded-lg"><TimeInWords time={x.duration} /></div>
                  </div>
                  <div class="col">
                    <div class="card-body d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                      <div class="d-flex flex-column">
                        <div class="font-heading">{truncate(x.post_title, 20)}</div>
                        <div class="text-small text-muted text-truncate">{truncate(x.post_content, 35)}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            )}

          </div>
        </div>
      </div>
    </div>
        </div>
      )
}
export default Footer;
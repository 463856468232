import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"
import countriesAndDialCodes from "../../utilities/countries"
import moneyFormat from "../../utilities/moneyFormat"

const VerifyCouponWidget = () => {

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Purchase Coupon")
    const [coupon, setCoupon] = useState(null);
    const [officialEmail, setOfficialEmail] = useState(null)
    const disclaimer = "Kindly note that Vendors are like other users and they are not affiliated to us, but given the task of selling coupon codes. Kindly report any vendor that you are not satisfied with. If you want to become a vendor, kindly write to us.";
    const [isLoading, setLoading] = useState(false)
    const [vendors, setVendors] = useState([])


    useEffect(() => {
      //websiteSettings()
        document.title = pageTile
    },[pageTile])



    const verifyCoupon = async () => {
        setLoading(true)
        
        let x = await requestHandler.post('vendor/coupon/verify', {coupon: coupon}, false, false)
        if(x != null && x.status === 1){
            toast.success(x.message)
            //setVendors(x.message)
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else{
            toast.error('Something went wrong')
        }
        setLoading(false)
    }

    return(
     
      <div class="row justify-content-center align-items-center">
                <div class="col-md-12 col-lg-12 col-xl-12">  
                <div className="card">
                <div className="card-body">
                    <h1>Verify Coupon</h1>
                <div className="mb-3 filled">
                      <input className="form-control" value={coupon} onChange={e => setCoupon(e.target.value)} placeholder="Enter Code" type="text"></input>
                      </div>
                      
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={verifyCoupon} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Verify</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                    </div>
                </div>
                </div>
    )
}

export default VerifyCouponWidget;